export const InfoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M10.0173 17C11.9992 16.9539 13.6469 16.2741 14.9605 14.9605C16.2741 13.6469 16.9539 11.9992 17 10.0173C16.9539 8.03539 16.2741 6.38765 14.9605 5.07407C13.6469 3.73745 11.9992 3.04609 10.0173 3C8.03539 3.04609 6.38765 3.73745 5.07407 5.07407C3.73745 6.38765 3.04609 8.03539 3 10.0173C3.04609 11.9992 3.73745 13.6469 5.07407 14.9605C6.38765 16.2741 8.03539 16.9539 10.0173 17ZM10.8469 6.21482C11.3539 6.26091 11.6305 6.53745 11.6765 7.04445C11.6305 7.57449 11.3539 7.86255 10.8469 7.90864C10.3399 7.86255 10.0634 7.57449 10.0173 7.04445C10.0634 6.53745 10.3399 6.26091 10.8469 6.21482ZM11.5728 13.4049L11.4691 13.4395C11.1465 13.5778 10.893 13.67 10.7086 13.716C10.5243 13.7621 10.3284 13.7852 10.121 13.7852C9.70617 13.7852 9.39506 13.693 9.18765 13.5086C8.98025 13.3243 8.86502 13.0938 8.84198 12.8173C8.84198 12.5407 8.87654 12.2642 8.94568 11.9877L9.36049 10.5012C9.36049 10.4782 9.36049 10.4667 9.36049 10.4667C9.40658 10.3053 9.45267 10.1095 9.49876 9.87901C9.52181 9.67161 9.48724 9.51029 9.39506 9.39506C9.25679 9.27984 9.08395 9.23375 8.87654 9.25679C8.66914 9.30288 8.48477 9.36049 8.32346 9.42963L8.42716 8.98025C8.91111 8.74979 9.40658 8.61152 9.91358 8.56543C10.6741 8.56543 11.0889 8.91111 11.158 9.60247C11.158 9.62551 11.158 9.66008 11.158 9.70617C11.135 9.75226 11.1235 9.83292 11.1235 9.94815C11.1235 9.94815 11.1235 9.95967 11.1235 9.98272C11.1004 10.0749 11.0774 10.1786 11.0543 10.2938C11.0313 10.4091 10.9621 10.6626 10.8469 11.0543C10.8239 11.1465 10.7893 11.2617 10.7432 11.4C10.7202 11.5383 10.6856 11.6881 10.6395 11.8494C10.5704 12.1029 10.5243 12.3449 10.5012 12.5753C10.5243 12.921 10.6741 13.0938 10.9506 13.0938C11.2272 13.1169 11.4691 13.0708 11.6765 12.9556L11.5728 13.4049Z"
      fill="#2953C0"
    />
  </svg>
);

export const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M8 8.83054L4.0018 12.8287C3.89268 12.9379 3.75552 12.9937 3.59032 12.9962C3.42513 12.9987 3.28545 12.9429 3.17128 12.8287C3.05709 12.7146 3 12.5761 3 12.4135C3 12.2508 3.05709 12.1124 3.17128 11.9982L7.16946 8L3.17128 4.0018C3.06215 3.89268 3.00632 3.75552 3.0038 3.59032C3.00127 3.42513 3.05709 3.28545 3.17128 3.17128C3.28545 3.05709 3.42387 3 3.58654 3C3.74921 3 3.88762 3.05709 4.0018 3.17128L8 7.16946L11.9982 3.17128C12.1073 3.06215 12.2445 3.00632 12.4097 3.0038C12.5749 3.00127 12.7146 3.05709 12.8287 3.17128C12.9429 3.28545 13 3.42387 13 3.58654C13 3.7492 12.9429 3.88762 12.8287 4.0018L8.83054 8L12.8287 11.9982C12.9379 12.1073 12.9937 12.2445 12.9962 12.4097C12.9987 12.5749 12.9429 12.7146 12.8287 12.8287C12.7146 12.9429 12.5761 13 12.4135 13C12.2508 13 12.1124 12.9429 11.9982 12.8287L8 8.83054Z"
      fill="#6B7280"
    />
  </svg>
);

export const TotalAmountIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="43"
    viewBox="0 0 42 43"
    fill="none"
  >
    <path
      d="M41.1198 24.6632C41.1363 25.9812 40.6231 27.2325 39.6947 28.1688C39.6927 28.1708 39.6908 28.1728 39.6889 28.1749C39.6814 28.1831 39.6769 28.1873 39.6696 28.1942C39.6638 28.1997 39.6563 28.2069 39.644 28.2191L38.4173 29.4368C37.8942 29.9559 37.5951 30.6609 37.5951 31.4049V33.1696C37.5951 35.9005 35.3572 38.1234 32.6059 38.1234H30.8263C30.0789 38.1234 29.3686 38.4184 28.8453 38.9377L27.6013 40.1725C26.6236 41.1447 25.348 41.6203 24.0707 41.6203C22.7934 41.6203 21.5174 41.1447 20.5395 40.1909L19.2788 38.9379C18.7555 38.4184 18.0452 38.1234 17.2977 38.1234H15.5181C12.7668 38.1234 10.529 35.9005 10.529 33.1696V31.4049C10.529 30.6602 10.2295 29.9569 9.70938 29.4222L9.70574 29.4186L8.46273 28.2019C6.52338 26.2781 6.50714 23.1338 8.44594 21.1945L9.10618 20.5584L9.16038 20.5029C9.70166 19.9358 10.7298 18.484 10.7298 17.7478V15.9919C10.7298 13.2508 12.9669 11.0227 15.7177 11.0227H17.4969C18.2449 11.0227 18.9548 10.7227 19.4774 10.2019L20.7217 8.96187C22.6597 7.01558 25.8252 7.01461 27.7814 8.94758L29.0417 10.2019C29.5644 10.7227 30.2743 11.0227 31.0223 11.0227H32.8015C35.5523 11.0227 37.7894 13.2508 37.7894 15.9919V17.7667C37.7894 18.5102 38.0886 19.2178 38.6114 19.7388L39.8564 20.9796C40.7996 21.8994 41.1198 23.346 41.1198 24.6632Z"
      fill="#FF4B2C"
      fillOpacity="0.1"
    />
    <path
      d="M31.7503 10.9471V10.1574C31.7503 7.31461 29.4379 5.00443 26.5945 5.00443H24.8303C24.1451 5.00443 23.4942 4.72872 23.0146 4.24921L21.7642 3.0008C19.743 0.997172 16.4715 0.997172 14.4674 3.01621L13.2342 4.24921C12.7546 4.72872 12.1037 5.00443 11.4185 5.00443H9.65429C6.81093 5.00443 4.49856 7.31461 4.49856 10.1574V11.9041C4.49856 12.6062 4.2245 13.257 3.7449 13.7365L2.49451 14.9883C0.490449 17.0073 0.507579 20.28 2.51163 22.2819L3.7449 23.4978C4.2245 23.9944 4.49856 24.6451 4.49856 25.3318V27.094C4.49856 29.9368 6.81093 32.2504 9.65429 32.2504H10.4387V31.2745C10.4387 30.5878 10.1646 29.9371 9.68507 29.4405L8.45179 28.2246C6.44775 26.2227 6.4306 22.95 8.43468 20.931L9.68507 19.6792C10.1646 19.1997 10.4387 18.5489 10.4387 17.8468V16.1C10.4387 13.2573 12.7511 10.9471 15.5945 10.9471H17.3587C18.0439 10.9471 18.6947 10.6714 19.1744 10.1919L20.4076 8.95891C22.4117 6.93989 25.6833 6.93989 27.7044 8.94352L28.9548 10.1919C29.4344 10.6714 30.0853 10.9471 30.7704 10.9471H31.7503Z"
      fill="url(#paint0_linear_1521_26801)"
    />
    <g opacity="0.5">
      <g filter="url(#filter0_f_1521_26801)">
        <path
          d="M32.3872 13.6741L33.617 14.9038C34.5904 15.8602 35.1199 17.1582 35.1199 18.5244C35.137 19.8908 34.6075 21.1904 33.6511 22.1622C33.6397 22.1748 33.6283 22.1858 33.6169 22.1968C33.6112 22.2023 33.6055 22.2078 33.5999 22.2134L32.3872 23.4261C31.9091 23.9042 31.6358 24.5532 31.6358 25.2381V26.9955C31.6358 29.8306 29.3301 32.1379 26.495 32.1379H24.7358C24.0526 32.1379 23.4037 32.4095 22.9254 32.8877L21.6957 34.1174C20.6881 35.1267 19.373 35.6203 18.0579 35.6203C16.7428 35.6203 15.4277 35.1267 14.4201 34.1361L13.1732 32.8877C12.695 32.4095 12.0461 32.1379 11.3629 32.1379H10.3859V31.1647C10.3859 30.4799 10.1126 29.8309 9.63442 29.3356L8.40471 28.123C6.40647 26.1265 6.38937 22.8627 8.38766 20.8492L9.63442 19.6007C10.1126 19.1225 10.3859 18.4735 10.3859 17.7733V16.0313C10.3859 13.1962 12.6916 10.8923 15.5267 10.8923H17.2859C17.969 10.8923 18.618 10.6173 19.0963 10.1391L20.3259 8.90943C22.3242 6.89587 25.5863 6.89587 27.6016 8.89408L28.8484 10.1391C29.3266 10.6173 29.9756 10.8923 30.6588 10.8923H31.6358V11.8654C31.6358 12.5469 31.9091 13.1959 32.3872 13.6741Z"
          fill="url(#paint1_linear_1521_26801)"
        />
      </g>
    </g>
    <path
      d="M28 21.5C28 21.8682 27.7015 22.1667 27.3333 22.1667H26.3333C25.9651 22.1667 25.6667 21.8682 25.6667 21.5V20.1667H22.3333V21.34C22.3278 21.4393 22.373 21.5347 22.4533 21.5933L27.0267 25.3667C27.6411 25.8717 27.998 26.6247 28 27.42V28.5C28 29.9728 26.8061 31.1667 25.3333 31.1667V31.8333C25.3333 32.2015 25.0349 32.5 24.6667 32.5H23.3333C22.9651 32.5 22.6667 32.2015 22.6667 31.8333V31.1667C21.1939 31.1667 20 29.9728 20 28.5V27.5C20 27.1318 20.2985 26.8333 20.6667 26.8333H21.6667C22.0349 26.8333 22.3333 27.1318 22.3333 27.5V28.8333H25.6667V27.66C25.6722 27.5607 25.627 27.4653 25.5467 27.4067L20.9733 23.6333C20.3589 23.1283 20.002 22.3753 20 21.58V20.5C20 19.0272 21.1939 17.8333 22.6667 17.8333V17.1667C22.6667 16.7985 22.9651 16.5 23.3333 16.5H24.6667C25.0349 16.5 25.3333 16.7985 25.3333 17.1667V17.8333C26.8061 17.8333 28 19.0272 28 20.5V21.5Z"
      fill="url(#paint2_linear_1521_26801)"
    />
    <defs>
      <filter
        id="filter0_f_1521_26801"
        x="0.2192"
        y="0.719078"
        width="41.5792"
        height="41.5794"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="3.33913"
          result="effect1_foregroundBlur_1521_26801"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1521_26801"
        x1="1713.24"
        y1="1.50019"
        x2="1713.24"
        y2="3425.71"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F5A623" />
        <stop offset="1" stopColor="#FDAD29" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1521_26801"
        x1="1418.04"
        y1="7.39528"
        x2="1418.04"
        y2="2829.69"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB945" />
        <stop offset="1" stopColor="#F5A623" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1521_26801"
        x1="24"
        y1="19.8684"
        x2="24"
        y2="31.0965"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00520833" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0.56" />
      </linearGradient>
    </defs>
  </svg>
);

export const WithdrawableBalanceIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="54"
    height="58"
    viewBox="0 0 54 58"
    fill="none"
  >
    <path
      d="M28.8145 10.8285L40.6784 13.9351C45.051 15.0801 46.8108 18.1801 45.6538 22.3995L41.7357 36.6899C40.982 39.4389 39.4397 41.1528 37.2778 41.7275C37.3628 41.1519 37.4065 40.5522 37.4065 39.9308V26.4681C37.4065 20.6348 33.5789 16.713 27.8628 16.713H19.9033L20.1736 15.7273C21.3304 11.5079 24.5112 9.70161 28.8145 10.8285Z"
      fill="url(#paint0_linear_1519_26701)"
    />
    <path
      d="M13.3908 16.5059H28.063C30.8818 16.5059 33.2208 17.4762 34.8557 19.1562C36.4913 20.8371 37.4369 23.2437 37.4369 26.1413V39.7161C37.4369 42.6053 36.4914 45.0077 34.8558 46.6865C33.2209 48.3646 30.8818 49.3348 28.063 49.3348H13.3908C10.572 49.3348 8.22856 48.3645 6.58942 46.6864C4.94957 45.0075 4 42.605 4 39.7161V26.1413C4 23.244 4.94967 20.8373 6.58951 19.1564C8.22864 17.4763 10.572 16.5059 13.3908 16.5059Z"
      fill="url(#paint1_linear_1519_26701)"
      fillOpacity="0.8"
      stroke="url(#paint2_linear_1519_26701)"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g opacity="0.5">
      <g filter="url(#filter0_f_1519_26701)">
        <path
          d="M37.7736 41.0901C37.8206 40.6539 37.8445 40.2049 37.8445 39.7439V26.2695C37.8445 20.4311 34.0253 16.5059 28.3219 16.5059H20.5476L16.9828 29.5478C15.8249 33.7839 17.6423 36.8097 21.88 37.9228L33.563 40.9915C35.1474 41.4076 36.5614 41.434 37.7736 41.0901Z"
          fill="url(#paint3_linear_1519_26701)"
        />
      </g>
    </g>
    <path
      d="M26.4 27H13.6C12.7163 27 12 27.7163 12 28.6V38.2C12 39.0837 12.7163 39.8 13.6 39.8H26.4C27.2837 39.8 28 39.0837 28 38.2V28.6C28 27.7163 27.2837 27 26.4 27ZM26.4 28.6V31H13.6V28.6H26.4ZM13.6 38.2V33.4H26.4V38.2H13.6ZM18 35H15.6C15.3791 35 15.2 35.1791 15.2 35.4V36.2C15.2 36.4209 15.3791 36.6 15.6 36.6H18C18.2209 36.6 18.4 36.4209 18.4 36.2V35.4C18.4 35.1791 18.2209 35 18 35Z"
      fill="url(#paint4_linear_1519_26701)"
    />
    <defs>
      <filter
        id="filter0_f_1519_26701"
        x="0.640625"
        y="0.505859"
        width="53.2041"
        height="56.8219"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="8"
          result="effect1_foregroundBlur_1519_26701"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1519_26701"
        x1="2318.57"
        y1="535.875"
        x2="198.919"
        y2="2041.99"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BFEC5E" />
        <stop offset="1" stopColor="#89C800" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1519_26701"
        x1="3378.44"
        y1="-13.6815"
        x2="45.8348"
        y2="3380.63"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B6ED3E" />
        <stop offset="1" stopColor="#89C800" stopOpacity="0.35" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1519_26701"
        x1="522.321"
        y1="383.569"
        x2="2633.56"
        y2="3046.82"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.25" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1519_26701"
        x1="1920.51"
        y1="434.113"
        x2="175.601"
        y2="1709.28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8FD000" />
        <stop offset="1" stopColor="#71A501" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1519_26701"
        x1="22.5"
        y1="31"
        x2="29.4628"
        y2="46.0172"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export const SecurityIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M7.80307 14.5779C7.72204 14.5777 7.64107 14.5589 7.56805 14.5208L5.21516 13.2953C4.5475 12.9472 4.02556 12.5571 3.61843 12.1032C2.72733 11.1106 2.23364 9.83816 2.22953 8.5196L2.21438 4.17976C2.21254 3.67887 2.53729 3.23019 3.02132 3.06219L7.35248 1.56621C7.60966 1.4752 7.89623 1.4747 8.1575 1.56391L12.4966 3.03214C12.9823 3.19569 13.3096 3.64297 13.3109 4.14319L13.326 8.48625C13.3308 9.8029 12.8469 11.0778 11.9639 12.0765C11.5587 12.5346 11.0391 12.9276 10.3761 13.2785L8.03711 14.5189C7.96453 14.5578 7.88411 14.5775 7.80307 14.5779Z"
      fill="url(#paint0_linear_1576_26204)"
    />
    <path
      d="M7.23582 9.64692C7.10914 9.6476 6.98275 9.60244 6.88566 9.50953L5.63664 8.31308C5.4438 8.12725 5.44362 7.82549 5.63674 7.63826C5.82985 7.45039 6.14325 7.44838 6.33676 7.63357L7.23547 8.494L9.45527 6.33766C9.64905 6.1498 9.96244 6.14778 10.1553 6.33299C10.3488 6.51882 10.349 6.82121 10.1559 7.0078L7.58568 9.50484C7.4888 9.59909 7.3625 9.64624 7.23582 9.64692Z"
      fill="url(#paint1_linear_1576_26204)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1576_26204"
        x1="185.699"
        y1="-109.01"
        x2="1375.25"
        y2="907.281"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#72DC60" />
        <stop offset="1" stopColor="#72DC60" stopOpacity="0.35" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1576_26204"
        x1="23.499"
        y1="16.5"
        x2="8.75758"
        y2="5.02546"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
  </svg>
);

export const DollarIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <g clipPath="url(#clip0_1555_43605)">
      <path
        d="M7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 5.14348 13.2625 3.36301 11.9497 2.05025C10.637 0.737498 8.85651 0 7 0Z"
        fill="#FB8C00"
      />
      <path
        d="M9.10039 5.4248C9.10039 5.6181 8.94369 5.7748 8.75039 5.7748H8.22539C8.03209 5.7748 7.87539 5.6181 7.87539 5.4248V4.7248H6.12539V5.34081C6.12248 5.39295 6.1462 5.44302 6.18839 5.4738L8.58939 7.4548C8.91196 7.71995 9.09934 8.11525 9.10039 8.5328V9.0998C9.10039 9.873 8.47359 10.4998 7.70039 10.4998V10.8498C7.70039 11.0431 7.54369 11.1998 7.35039 11.1998H6.65039C6.45709 11.1998 6.30039 11.0431 6.30039 10.8498V10.4998C5.52719 10.4998 4.90039 9.873 4.90039 9.0998V8.57481C4.90039 8.38151 5.05709 8.2248 5.25039 8.2248H5.77539C5.96869 8.2248 6.12539 8.38151 6.12539 8.57481V9.27481H7.87539V8.6588C7.8783 8.60666 7.85459 8.55659 7.81239 8.52581L5.41139 6.54481C5.08882 6.27966 4.90144 5.88436 4.90039 5.4668V4.8998C4.90039 4.12661 5.52719 3.4998 6.30039 3.4998V3.1498C6.30039 2.95651 6.45709 2.7998 6.65039 2.7998H7.35039C7.54369 2.7998 7.70039 2.95651 7.70039 3.1498V3.4998C8.47359 3.4998 9.10039 4.12661 9.10039 4.8998V5.4248Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1555_43605">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const BankCardIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <rect width="30" height="30" rx="8" fill="#E6EBFF" />
    <line
      x1="5.40039"
      y1="13.4707"
      x2="24.3261"
      y2="13.4707"
      stroke="white"
      strokeWidth="5"
    />
    <path
      d="M5.40039 13.1475H24.3261V19.1989C24.3261 20.8557 22.983 22.1989 21.3261 22.1989H8.40039C6.74354 22.1989 5.40039 20.8557 5.40039 19.1989V13.1475Z"
      fill="url(#paint0_linear_1555_43915)"
    />
    <path
      d="M5.40039 10.7988C5.40039 9.14198 6.74354 7.79883 8.40039 7.79883H21.3261C22.983 7.79883 24.3261 9.14197 24.3261 10.7988V12.3245H5.40039V10.7988Z"
      fill="#4C70FF"
    />
    <g filter="url(#filter0_b_1555_43915)">
      <line
        x1="5.40039"
        y1="13.2207"
        x2="24.3261"
        y2="13.2207"
        stroke="white"
        strokeOpacity="0.7"
        strokeWidth="3"
      />
    </g>
    <g filter="url(#filter1_b_1555_43915)">
      <circle
        cx="9.51486"
        cy="18.0842"
        r="1.64571"
        fill="white"
        fillOpacity="0.77"
      />
    </g>
    <defs>
      <filter
        id="filter0_b_1555_43915"
        x="3.40039"
        y="9.7207"
        width="22.9258"
        height="7"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="1" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_1555_43915"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1555_43915"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_b_1555_43915"
        x="4.86914"
        y="13.4385"
        width="9.29102"
        height="9.2915"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.5" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_1555_43915"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1555_43915"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1555_43915"
        x1="14.8632"
        y1="13.1475"
        x2="14.8632"
        y2="22.1989"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4C70FF" />
        <stop offset="1" stopColor="#1537BB" />
      </linearGradient>
    </defs>
  </svg>
);

export const EditIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <mask
      id="mask0_1555_43927"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="16"
    >
      <rect width="16" height="16" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_1555_43927)">
      <path
        d="M3.33299 12.6668H4.17401L10.9984 5.84243L10.1574 5.00141L3.33299 11.8258V12.6668ZM2.33301 13.6668V11.4104L11.1266 2.62068C11.2274 2.52911 11.3387 2.45836 11.4605 2.40841C11.5824 2.35847 11.7101 2.3335 11.8438 2.3335C11.9774 2.3335 12.1069 2.35722 12.2322 2.40466C12.3575 2.4521 12.4684 2.52752 12.565 2.63093L13.3791 3.45528C13.4825 3.55186 13.5563 3.66299 13.6003 3.78868C13.6443 3.91436 13.6663 4.04004 13.6663 4.16571C13.6663 4.29977 13.6434 4.4277 13.5976 4.54951C13.5518 4.67134 13.479 4.78265 13.3791 4.88346L4.58939 13.6668H2.33301ZM10.5705 5.4293L10.1574 5.00141L10.9984 5.84243L10.5705 5.4293Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export const DeleteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <mask
      id="mask0_1555_43933"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="16"
    >
      <rect width="16" height="16" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_1555_43933)">
      <path
        d="M4.8718 13.6665C4.53932 13.6665 4.25535 13.5488 4.01988 13.3133C3.78441 13.0778 3.66667 12.7939 3.66667 12.4614V3.99987H3V2.99989H5.99998V2.41016H9.99998V2.99989H13V3.99987H12.3333V12.4614C12.3333 12.7981 12.2166 13.0832 11.9833 13.3165C11.75 13.5499 11.4649 13.6665 11.1282 13.6665H4.8718ZM11.3333 3.99987H4.66665V12.4614C4.66665 12.5212 4.68588 12.5704 4.72435 12.6088C4.76282 12.6473 4.81197 12.6665 4.8718 12.6665H11.1282C11.1795 12.6665 11.2265 12.6452 11.2692 12.6024C11.3119 12.5597 11.3333 12.5127 11.3333 12.4614V3.99987ZM6.26923 11.3332H7.26922V5.33321H6.26923V11.3332ZM8.73075 11.3332H9.73073V5.33321H8.73075V11.3332Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export const WarningIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
  >
    <g clipPath="url(#clip0_1576_27563)">
      <path
        d="M14.5953 2.54559C14.4344 2.26538 14.2025 2.03257 13.9229 1.87068C13.6432 1.70879 13.3259 1.62354 13.0028 1.62354C12.6797 1.62354 12.3623 1.70879 12.0827 1.87068C11.8031 2.03257 11.5711 2.26538 11.4103 2.54559L0.26764 21.5045C-0.474985 22.7687 0.415515 24.3758 1.86014 24.3758H24.1438C25.5884 24.3758 26.4805 22.7671 25.7363 21.5045L14.5953 2.54559ZM12.9995 8.12584C13.8689 8.12584 14.5498 8.87659 14.462 9.74271L13.8933 15.4416C13.8742 15.6655 13.7717 15.874 13.6062 16.026C13.4407 16.178 13.2242 16.2623 12.9995 16.2623C12.7748 16.2623 12.5583 16.178 12.3928 16.026C12.2273 15.874 12.1249 15.6655 12.1058 15.4416L11.537 9.74271C11.5166 9.53834 11.5392 9.33196 11.6034 9.13686C11.6676 8.94176 11.7719 8.76226 11.9097 8.60994C12.0475 8.45762 12.2156 8.33585 12.4033 8.25247C12.591 8.16909 12.7941 8.12595 12.9995 8.12584ZM13.0028 17.8758C13.4337 17.8758 13.8471 18.047 14.1518 18.3518C14.4566 18.6565 14.6278 19.0699 14.6278 19.5008C14.6278 19.9318 14.4566 20.3451 14.1518 20.6499C13.8471 20.9546 13.4337 21.1258 13.0028 21.1258C12.5718 21.1258 12.1585 20.9546 11.8537 20.6499C11.549 20.3451 11.3778 19.9318 11.3778 19.5008C11.3778 19.0699 11.549 18.6565 11.8537 18.3518C12.1585 18.047 12.5718 17.8758 13.0028 17.8758Z"
        fill="#FCBF02"
      />
    </g>
    <defs>
      <clipPath id="clip0_1576_27563">
        <rect width="26" height="26" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const SuccessIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 13C1 6.37258 6.37258 1 13 1C16.1826 1 19.2348 2.26428 21.4853 4.51472C23.7357 6.76516 25 9.8174 25 13C25 19.6274 19.6274 25 13 25C6.37258 25 1 19.6274 1 13ZM11.476 17.0201L18.22 10.2761C18.441 10.0444 18.441 9.67992 18.22 9.44814L17.584 8.81214C17.3507 8.58345 16.9773 8.58345 16.744 8.81214L11.056 14.5001L9.25602 12.7121C9.14714 12.5961 8.99512 12.5303 8.83602 12.5303C8.67691 12.5303 8.5249 12.5961 8.41602 12.7121L7.78002 13.3481C7.66643 13.4608 7.60254 13.6142 7.60254 13.7741C7.60254 13.9341 7.66643 14.0875 7.78002 14.2001L10.636 17.0201C10.7449 17.1362 10.8969 17.202 11.056 17.202C11.2151 17.202 11.3671 17.1362 11.476 17.0201Z"
      fill="#00B67A"
    />
  </svg>
);
